<template>
  <!-- layout-container lesson 2 -->
  <div class="lesson-two-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
// import SyllabusPageB from "@/components/Course/CoursePage/SyllabusPageB";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import RocketGame from "./4_RocketGame.vue";
import DragGamePageOne from "./5_DragGameOne.vue";
import DragGamePageTwo from "./6_DragGameTwo.vue";
import MoveGame from "./7_MoveGame.vue";
import ReviewGame from "./8_ReviewGame.vue";
import SpeakingPage from "./9_SpeakingPage.vue";
import RecognitionPage_heng from "./10_RecognitionPage_heng.vue";
import PracticePageOne_heng from "./11_PracticePageOne_heng.vue";
import PracticePageTwo_heng from "./12_PracticePageTwo_heng.vue";
import writePage_heng from "./13_writePage_heng.vue";
import RecognitionPage_shu from "./14_RecognitionPage_shu.vue";
import PracticePageOne_shu from "./15_PracticePageOne_shu.vue";
import PracticePageTwo_shu from "./16_PracticePageTwo_shu.vue";
import writePage_shu from "./17_writePage_shu.vue";
import PracticeGame from "./18_PracticeGame.vue";
import WriteGame from "./19_WriteGame.vue";
import LearnReview from "./20_LearnReview.vue";
import RankMapPageTwo from "./21_RankMapPageTwo.vue";
import ByePage from "./22_ByePage.vue";
export default {
  name: "Lesson2",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    DragGamePageTwo,
    MoveGame,
    ReviewGame,
    SpeakingPage,
    RecognitionPage_heng,
    PracticePageOne_heng,
    PracticePageTwo_heng,
    writePage_heng,
    RecognitionPage_shu,
    PracticePageOne_shu,
    PracticePageTwo_shu,
    writePage_shu,
    PracticeGame,
    WriteGame,
    LearnReview,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        RocketGame,
        DragGamePageOne,
        DragGamePageTwo,
        MoveGame,
        ReviewGame,
        SpeakingPage,
        RecognitionPage_heng,
        PracticePageOne_heng,
        PracticePageTwo_heng,
        writePage_heng,
        RecognitionPage_shu,
        PracticePageOne_shu,
        PracticePageTwo_shu,
        writePage_shu,
        PracticeGame,
        WriteGame,
        LearnReview,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-two-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
