<template>
  <div class="write-page-shu-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImgObj"
      :strokePinyin="strokePinyin"
      :strokeImgFill="strokeImgFill"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "shù",
      strokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-shu-grey.svg"),
      strokeImgFill: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-shu-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G20-writing-hanzi/sheep-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G20-writing-hanzi/sheep-2.svg"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G20-writing-hanzi/sheep-3.svg"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G20-writing-hanzi/sheep-4.svg"),
        },
      ],
      flagImgObj: {
        img: require("@/assets/img/16-GAMES/G20-writing-hanzi/life-lost.svg"),
        imgActive: require("@/assets/img/16-GAMES/G20-writing-hanzi/life-remaining.svg"),
        pinyin: "shù",
        tipImg:require('@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-shu-red.gif')

      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-shu-container {
  width: 100%;
  height: 100%;
}
</style>
