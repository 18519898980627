<template>
  <div class="customlesson layout-border">
    <div class="customlesson-content-five position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        :numBers="numBers"
        @clickNext="nextPage"
        @updateNumbers="updateNumbers($event)"
      ></PageButton>
      <div class="right-gules">
        <div class="pinyin font-pinyin-medium stroke-pinyin">shù</div>
        <div class="target-stroke-img">
          <img
            src="@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-shu-grey.svg"
            alt=""
          />
        </div>
      </div>
      <div class="picture-one" ref="pictureOne">
        <div class="picture-one-content">
          <div class="picture-one-content-window" ref="content">
            <div class="window-total">
              <div class="picture-one-content-trash">
                <img
                  src="@/assets/img/16-GAMES/G17-window-cleaning/cleaning-materials.svg"
                  alt=""
                />
              </div>
              <div class="window">
                <img
                  src="@/assets/img/16-GAMES/G17-window-cleaning/window.svg"
                  alt=""
                />
                <div class="clean-windows" v-if="numBers == 1">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"
                    alt=""
                  />
                </div>
                <div class="stars" v-if="numBers > 2">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="window">
                <img
                  src="@/assets/img/16-GAMES/G17-window-cleaning/window.svg"
                  alt=""
                />
                <div class="clean-windows" v-if="numBers < 7">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"
                    alt=""
                  />
                </div>
                <div class="stars" v-if="numBers > 5">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"
                    alt=""
                  />
                </div>
                <div class="cloud" :class="{ fadeout: !cloudDisplayOne }">
                  <img
                    src="@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="window">
                <img
                  src="@/assets/img/16-GAMES/G17-window-cleaning/window.svg"
                  alt=""
                />
                <div class="clean-windows" v-if="numBers < 11">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"
                    alt=""
                  />
                </div>
                <div class="stars" v-if="numBers > 10">
                  <img
                    src="@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"
                    alt=""
                  />
                </div>
                <div class="cloud" :class="{ fadeout: !cloudDisplayTwo }">
                  <img
                    src="@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="train-picture">
              <div class="typeface-picture">
                <span class="pinyin font-pinyin-medium">shí</span>
                <div class="block">
                  <img src="@/assets/img/11-Syllabus/square.svg" alt="" />
                  <div class="block-da">
                    <img
                      src="@/assets/img/16-GAMES/G17-window-cleaning/shi-grey.svg"
                      alt=""
                    />
                    <div
                      :ref="'stroke_1'"
                      class="stroke1"
                      @click.stop="clickedStroke(1, 0, 0)"
                    />
                    <div
                      class="red-one"
                      v-if="characterList[0][0].Viewstroke == true"
                    >
                      <img
                        src="@/assets/img/16-GAMES/G17-window-cleaning/shi-stroke.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="typeface-picture">
                <span class="pinyin font-pinyin-medium">fēi</span>
                <div class="block">
                  <img src="@/assets/img/11-Syllabus/square.svg" alt="" />
                  <div class="block-da">
                    <div
                      :ref="'stroke_2'"
                      class="stroke2"
                      @click.stop="clickedStroke(2, 1, 0)"
                    />
                    <div
                      :ref="'stroke_3'"
                      class="stroke3"
                      @click.stop="clickedStroke(3, 1, 1)"
                    />
                    <div class="red-two" v-if="numBers > 3">
                      <img
                        class="heng-one"
                        v-if="characterList[1][0].Viewstroke == true"
                        src="@/assets/img/16-GAMES/G17-window-cleaning/fei-1-stroke.svg"
                        alt=""
                      />
                      <img
                        class="heng-two"
                        v-if="characterList[1][1].Viewstroke == true"
                        src="@/assets/img/16-GAMES/G17-window-cleaning/fei-2-stroke.svg"
                        alt=""
                      />
                    </div>
                    <img
                      src="@/assets/img/16-GAMES/G17-window-cleaning/fei-grey.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="cloud" v-if="cloudDisplayBottomOne">
                  <img
                    src="@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="typeface-picture">
                <span class="pinyin font-pinyin-medium">jiān</span>
                <div class="block">
                  <img src="@/assets/img/11-Syllabus/square.svg" alt="" />
                  <div class="block-da">
                    <div
                      :ref="'stroke_4'"
                      class="stroke4"
                      @click.stop="clickedStroke(4, 2, 0)"
                    />
                    <div
                      :ref="'stroke_5'"
                      class="stroke5"
                      @click.stop="clickedStroke(5, 2, 1)"
                    />
                    <div
                      :ref="'stroke_6'"
                      class="stroke6"
                      @click.stop="clickedStroke(6, 2, 2)"
                    />
                    <div class="red-two" v-if="numBers > 7">
                      <img
                        class="heng-one"
                        v-if="characterList[2][0].Viewstroke == true"
                        src="@/assets/img/16-GAMES/G17-window-cleaning/jian-3-stroke.svg"
                        alt=""
                      />
                      <img
                        class="heng-two"
                        v-if="characterList[2][1].Viewstroke == true"
                        src="@/assets/img/16-GAMES/G17-window-cleaning/jian-2-stroke.svg"
                        alt=""
                      />
                      <img
                        class="heng-three"
                        v-if="characterList[2][2].Viewstroke == true"
                        src="@/assets/img/16-GAMES/G17-window-cleaning/jian-1-stroke.svg"
                        alt=""
                      />
                    </div>
                    <img
                      src="@/assets/img/16-GAMES/G17-window-cleaning/jian-grey.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="cloud" :class="{ fadeout: !cloudDisplayBottomTwo }">
                  <img
                    src="@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <!-- 下面左边图片 -->
      <div
        class="customlesson_bottom_left_img_two"
        v-if="numBers == 11"
        @mouseover="mouseover(3)"
        @mouseleave="mouseleave(3)"
        @click.stop="handleClickImg"
      >
        <img
          v-if="numBersOne === 1"
          src="@/assets/img/04-Buttons/button-next.svg"
          alt=""
        />
        <img
          v-if="numBersOne === 2"
          src="@/assets/img/04-Buttons/button-next-hover.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: ["knowSum"],
  data() {
    return {
      characterList: [
        [
          {
            Viewstroke: false,
          },
        ],
        [
          {
            Viewstroke: false,
          },
          {
            Viewstroke: false,
          },
        ],
        [
          {
            Viewstroke: false,
          },
          {
            Viewstroke: false,
          },
          {
            Viewstroke: false,
          },
        ],
      ],

      control: 0,
      scrollTop: 0,
      hoverClass: 1,
      numBers: 1,
      pictureDefault: 1,
      fontSizeSum: " ",
      hoverClassOne: 1,
      numBersOne: 1,
      numBersBlooFree: false,
      numBersBlooTwo: false,
      numBersBloo: false,
      instructionsDisplay: true,
      cloudDisplayOne: true,
      cloudDisplayBottomOne: true,
      cloudDisplayBottomTwo: true,
      cloudDisplayTwo: true,
      strokeInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "next",
            startNumberRange: 99,
            endNumberRange: 99,
          },
        ],
      },
      clickedStrokeValue: false,
    };
  },
  beforeMount() {
    this.pictureWidth();
  },
  mounted() {
    this.pictureWidth();
    this.$bus.$on("clickPracticePageTwo", ({ strokeNum, card, stroke }) => {
      this.clickedStroke(strokeNum, card, stroke, 1);
    });
  },
  watch: {
    strokeInfo: {
      handler(value) {
        if (!this.clickedStrokeValue) {
          const data = {
            clickType: 30016,
            data: { value },
            text: "PracticePageTwo按钮socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      },
      deep: true,
    },
  },
  methods: {
    clickedStroke(strokeNum, card, stroke, type) {
      if (type != 1) {
        this.clickedStrokeValue = false;
      } else {
        this.clickedStrokeValue = true;
      }
      var ref = "stroke_" + strokeNum;
      console.log(this.$refs[ref]);

      this.characterList[card][stroke].Viewstroke = true;
      this.handleClickImg();
      this.$refs[ref].style.pointerEvents = "none";
      this.strokeInfo = { strokeNum, card, stroke };
    },
    pictureWidth() {
      this.$nextTick(() => {
        let pictureTrainWidth =
          document.getElementsByClassName("train-picture")[0];
        let pictureWidth = document.getElementsByClassName(
          "picture-one-content"
        )[0];
        pictureWidth.style.width = String(this.$refs.content.width) + "px";
        pictureTrainWidth.style.width = String(this.$refs.content.width) + "px";
      });
    },
    mouseover(type) {
      if (type === 1) {
        this.hoverClass = 2;
      } else if (type === 2) {
        this.hoverClassOne = 2;
      } else if (type === 3) {
        this.numBersOne = 2;
      }
    },
    mouseleave(type) {
      if (type === 1) {
        this.hoverClass = 1;
      } else if (type === 2) {
        this.hoverClassOne = 1;
      } else if (type === 3) {
        this.numBersOne = 1;
      }
    },
    pictureClick() {
      this.pictureWidth();
      if (this.numBers == 3) {
        let speed = 0;
        const timer = setInterval(() => {
          speed += 2;
          this.$refs.pictureOne.scrollLeft = speed;
          if (
            this.$refs.pictureOne.scrollLeft >
            (this.$refs.pictureOne.scrollWidth -
              this.$refs.pictureOne.clientWidth) /
              2
          ) {
            clearInterval(timer);
            this.cloudDisplayOne = false;
            console.log("tingzhi2");
          }
        }, 10);
      } else if (this.numBers == 6) {
        let speed =
          (this.$refs.pictureOne.scrollWidth -
            this.$refs.pictureOne.clientWidth) /
          2;
        const timer = setInterval(() => {
          speed += 2;
          this.$refs.pictureOne.scrollLeft = speed;
          // console.log(
          //   this.$refs.pictureOne.scrollLeft,
          //   this.$refs.pictureOne.scrollWidth,
          //   this.$refs.pictureOne.clientWidth
          // );
          if (
            this.$refs.pictureOne.scrollLeft >=
            this.$refs.pictureOne.scrollWidth -
              this.$refs.pictureOne.clientWidth
          ) {
            clearInterval(timer);
            this.cloudDisplayTwo = false;
            console.log("tingzhi2");
          }
        }, 10);
        this.numBers += 1;
      }
    },
    handleClickImg() {
      // console.log("鼠标X:", e.offsetX, "鼠标Y:", e.offsetY);
      this.numBers += 1;
      if (this.numBers == 2) {
        this.instructionsDisplay = false;
        playCorrectSound(true, false);
        this.numBers += 1;
        this.timer = setTimeout(() => {
          this.pictureClick();
          this.cloudDisplayOne = false;
          this.cloudDisplayBottomOne = false;
        }, 1000);
      } else if (this.numBers == 5) {
        playCorrectSound(true, false);
        this.numBers += 1;
        this.timer = setTimeout(() => {
          this.pictureClick();
          this.cloudDisplayTwo = false;
          this.cloudDisplayBottomTwo = false;
        }, 1000);
      } else if (this.numBers == 10) {
        playCorrectSound();
        startConfetti();
        this.numBers += 1;
        this.timer = setTimeout(() => {
          this.pictureClick();
          this.cloudDisplayTwo = false;
          this.cloudDisplayBottomTwo = false;
        }, 1000);
      } else if (this.numBers == 11) {
        this.isLastStep = true;
      } else if (this.numBers == 12) {
        this.$bus.$emit("nextButton", true);
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
<style scoped lang="scss">
.stroke1 {
  //  background-color: black;
  height: 85%;
  width: 18%;
  left: 39%;
  top: 8%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.stroke2 {
  //  background-color: black;
  height: 72%;
  width: 15%;
  left: 32%;
  top: 15%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.stroke3 {
  // background-color: black;
  height: 87%;
  width: 14%;
  left: 51%;
  top: 7%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.stroke4 {
  // background-color: black;
  height: 33%;
  width: 8%;
  left: 14%;
  top: 22%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.stroke5 {
  //  background-color: black;
  height: 47%;
  width: 10%;
  left: 26%;
  top: 11%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.stroke6 {
  //  background-color: black;
  height: 29%;
  width: 10%;
  left: 39%;
  top: 52%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.customlesson {
  // width: 100%;
  // height: 100%;
  position: relative;
  .customlesson-content-five {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/img/03-Backgrounds/background-practice.svg");
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    // padding: 5% 0 0 0;
    .right-gules {
      width: 15%;
      height: 15%;
      background-color: #cd4c3f;
      position: absolute;
      right: 0;
      top: 5%;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .stroke-pinyin {
        flex: 3;
        display: flex;
        align-items: center;
        color: #fff;
      }
      .target-stroke-img {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 10px;
        img {
          width: 20%;
          color: #fff;
        }
      }
    }
    .picture-one {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      border-radius: 58px;
      position: relative;
      .picture-one-content {
        height: 100%;
        position: relative;
        .picture-one-content-window {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-flow: column;
          .picture-one-content-trash {
            position: absolute;
            top: 10%;
            left: 5%;
            img {
              width: 100%;
            }
          }
          .window-total {
            width: 70%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 50%;
            .window {
              // width: 60%;
              margin-left: 30%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              img {
                // width: 100%;
              }
              .clean-windows {
                position: absolute;
                bottom: 0;
                right: 0;
                img {
                  width: 100%;
                }
              }
              .stars {
                position: absolute;
                top: 0;
                left: 0;
                img {
                  width: 100%;
                }
              }
              .cloud {
                width: 100%;
                position: absolute;
                top: 2%;
                left: 2%;
                img {
                  width: 100%;
                }
              }
            }
          }
          .train-picture {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 55%;
            .typeface-picture {
              width: 50%;
              height: 60%;
              margin-right: 18%;
              background-color: #fff;
              border-radius: 30px;
              position: relative;
              border-right: 10px solid #2258a7;
              border-bottom: 10px solid #2258a7;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-flow: column;
              padding: 5% 2%;
              .block {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                img {
                  height: 100%;
                }
                .block-da {
                  height: 100%;
                  width: 100%;
                  // object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    height: 100%;
                  }
                  .red-one {
                    height: 100%;
                    position: absolute;
                    top: 1%;
                    img {
                      height: 100%;
                    }
                  }
                  .red-two {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 1%;
                    // left:-5%;
                    .heng-one {
                      position: absolute;
                      left: 0%;
                    }
                    .heng-two {
                      position: absolute;
                      left: 0%;
                    }
                    .heng-three {
                      position: absolute;
                      left: 0%;
                    }
                  }
                }
              }
              span {
                color: #cd4c3f;
                margin-top: 2%;
              }
              .cloud {
                width: 100%;
                position: absolute;
                bottom: 15%;
                left: -10%;
                img {
                  width: 120%;
                }
              }
              .instructions {
                width: 100%;
                position: absolute;
                bottom: 15%;
                left: 50%;
                cursor: pointer;
                img {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .customlesson_bottom_left_img_two {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%;
      border-top-left-radius: 58px;
      text-align: center;
      z-index: 9999;
      img {
        width: 100%;
      }
    }
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
.py-font-size {
  font-size: 1.5em;
}
.fadeout {
  color: white;
  -webkit-animation: fadeout 0.5s ease-in forwards;
  -moz-animation: fadeout 0.5s ease-in forwards;
  animation: fadeout 0.5s ease-in forwards;
}
</style>
