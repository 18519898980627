var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "write-page-heng-container" },
    [
      _c("WriteStrokePage", {
        attrs: {
          strokeImage: _vm.strokeImg,
          backgroundImageList: _vm.bgImgList,
          flagImageObj: _vm.flagImgObj,
          strokeImgFill: _vm.strokeImgFill,
          strokePinyin: _vm.strokePinyin,
          lesson: _vm.lesson,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }