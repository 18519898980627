var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customlesson layout-border" }, [
    _c(
      "div",
      { staticClass: "customlesson-content-five position-relative" },
      [
        _c("PageButton", {
          attrs: {
            bottomCornerButtonList: _vm.bottomCornerButtonList,
            isLastStep: _vm.isLastStep,
            numBers: _vm.numBers,
          },
          on: {
            clickNext: _vm.nextPage,
            updateNumbers: function ($event) {
              return _vm.updateNumbers($event)
            },
          },
        }),
        _vm._m(0),
        _c("div", { ref: "pictureOne", staticClass: "picture-one" }, [
          _c("div", { staticClass: "picture-one-content" }, [
            _c(
              "div",
              { ref: "content", staticClass: "picture-one-content-window" },
              [
                _c("div", { staticClass: "window-total" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "window" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G17-window-cleaning/window.svg"),
                        alt: "",
                      },
                    }),
                    _vm.numBers == 1
                      ? _c("div", { staticClass: "clean-windows" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.numBers > 2
                      ? _c("div", { staticClass: "stars" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "window" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G17-window-cleaning/window.svg"),
                        alt: "",
                      },
                    }),
                    _vm.numBers < 7
                      ? _c("div", { staticClass: "clean-windows" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.numBers > 5
                      ? _c("div", { staticClass: "stars" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "cloud",
                        class: { fadeout: !_vm.cloudDisplayOne },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "window" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G17-window-cleaning/window.svg"),
                        alt: "",
                      },
                    }),
                    _vm.numBers < 11
                      ? _c("div", { staticClass: "clean-windows" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/cleaning-guy.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm.numBers > 10
                      ? _c("div", { staticClass: "stars" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G17-window-cleaning/stars.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "cloud",
                        class: { fadeout: !_vm.cloudDisplayTwo },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "train-picture" }, [
                  _c("div", { staticClass: "typeface-picture" }, [
                    _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v("shí"),
                    ]),
                    _c("div", { staticClass: "block" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/11-Syllabus/square.svg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "block-da" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G17-window-cleaning/shi-grey.svg"),
                            alt: "",
                          },
                        }),
                        _c("div", {
                          ref: "stroke_1",
                          staticClass: "stroke1",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(1, 0, 0)
                            },
                          },
                        }),
                        _vm.characterList[0][0].Viewstroke == true
                          ? _c("div", { staticClass: "red-one" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/16-GAMES/G17-window-cleaning/shi-stroke.svg"),
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "typeface-picture" }, [
                    _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v("fēi"),
                    ]),
                    _c("div", { staticClass: "block" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/11-Syllabus/square.svg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "block-da" }, [
                        _c("div", {
                          ref: "stroke_2",
                          staticClass: "stroke2",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(2, 1, 0)
                            },
                          },
                        }),
                        _c("div", {
                          ref: "stroke_3",
                          staticClass: "stroke3",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(3, 1, 1)
                            },
                          },
                        }),
                        _vm.numBers > 3
                          ? _c("div", { staticClass: "red-two" }, [
                              _vm.characterList[1][0].Viewstroke == true
                                ? _c("img", {
                                    staticClass: "heng-one",
                                    attrs: {
                                      src: require("@/assets/img/16-GAMES/G17-window-cleaning/fei-1-stroke.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm.characterList[1][1].Viewstroke == true
                                ? _c("img", {
                                    staticClass: "heng-two",
                                    attrs: {
                                      src: require("@/assets/img/16-GAMES/G17-window-cleaning/fei-2-stroke.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G17-window-cleaning/fei-grey.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _vm.cloudDisplayBottomOne
                      ? _c("div", { staticClass: "cloud" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "typeface-picture" }, [
                    _c("span", { staticClass: "pinyin font-pinyin-medium" }, [
                      _vm._v("jiān"),
                    ]),
                    _c("div", { staticClass: "block" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/11-Syllabus/square.svg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "block-da" }, [
                        _c("div", {
                          ref: "stroke_4",
                          staticClass: "stroke4",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(4, 2, 0)
                            },
                          },
                        }),
                        _c("div", {
                          ref: "stroke_5",
                          staticClass: "stroke5",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(5, 2, 1)
                            },
                          },
                        }),
                        _c("div", {
                          ref: "stroke_6",
                          staticClass: "stroke6",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clickedStroke(6, 2, 2)
                            },
                          },
                        }),
                        _vm.numBers > 7
                          ? _c("div", { staticClass: "red-two" }, [
                              _vm.characterList[2][0].Viewstroke == true
                                ? _c("img", {
                                    staticClass: "heng-one",
                                    attrs: {
                                      src: require("@/assets/img/16-GAMES/G17-window-cleaning/jian-3-stroke.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm.characterList[2][1].Viewstroke == true
                                ? _c("img", {
                                    staticClass: "heng-two",
                                    attrs: {
                                      src: require("@/assets/img/16-GAMES/G17-window-cleaning/jian-2-stroke.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm.characterList[2][2].Viewstroke == true
                                ? _c("img", {
                                    staticClass: "heng-three",
                                    attrs: {
                                      src: require("@/assets/img/16-GAMES/G17-window-cleaning/jian-1-stroke.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G17-window-cleaning/jian-grey.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "cloud",
                        class: { fadeout: !_vm.cloudDisplayBottomTwo },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _c("div"),
          ]),
        ]),
        _vm.numBers == 11
          ? _c(
              "div",
              {
                staticClass: "customlesson_bottom_left_img_two",
                on: {
                  mouseover: function ($event) {
                    return _vm.mouseover(3)
                  },
                  mouseleave: function ($event) {
                    return _vm.mouseleave(3)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleClickImg.apply(null, arguments)
                  },
                },
              },
              [
                _vm.numBersOne === 1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/04-Buttons/button-next.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.numBersOne === 2
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/04-Buttons/button-next-hover.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-gules" }, [
      _c("div", { staticClass: "pinyin font-pinyin-medium stroke-pinyin" }, [
        _vm._v("shù"),
      ]),
      _c("div", { staticClass: "target-stroke-img" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-shu-grey.svg"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "picture-one-content-trash" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/16-GAMES/G17-window-cleaning/cleaning-materials.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }