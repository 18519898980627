<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImgObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
      :lesson="lesson"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "héng",

      strokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-heng-grey.svg"),
      strokeImgFill: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-heng-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/tree-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/pouring-water1.gif"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/pouring-water2.gif"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/pouring-water3.gif"),
        },
      ],
      flagImgObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/icon-life-lost.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/icon-life-remaining.svg"),
        pinyin: "héng",
        tipImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-02/lesson-02-heng-red.gif"),
      },
    };
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
