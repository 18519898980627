<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/room.svg"),
      gameIndex: 2,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-chair.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-chair-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-desk.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-desk-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-lamp.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-lamp-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-wardrobe.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-wardrobe-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-window.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/stroke-window-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/shu-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-02-2/shu-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
